/* Main */
:root {
  --header-height: 4rem;
  --text-decoration-link: none;
  --text-decoration-link-hover: underline;
}

// Colors
:root {
  --color-solid-orange: #ff9012;
  --color-solid-orange-hover: #f68607;
  --color-ok: #52e157;
  --color-warning: #fd2020;
  --color-active: #4779f6;
  --color-s1: #ff687a;
  --color-s1p: #3988ff;
  --color-s2p: #00bce5;
  --color-s3: #813bf6;
  --color-o1: #00ce9d;
  --color-o2: #d13bf6;
  --color-gray6: #f5f5f5;
  --color-gray5: #f7f7f7;
  --color-gray4: #eaeaea;
  --color-gray3: #dedede;
  --color-gray2: #b3b3b3;
  --color-gray1: #8d8d8d;
  --color-solid-black: #111111;
  --color-solid-black-hover: #393939;
  --color-box-shadow-transparent: rgba(0, 0, 0, 0.1);
  --color-black: rgba(0, 0, 0, 1);
  --color-white: rgba(255, 255, 255, 1);
  --color-overlay-transparent: rgba(35, 33, 32, 0.3);
  --color-text: #111111;
  --color-text-subdued: #393939;
  --color-border: #e5e5e5;
  --color-dimmed: #f7f7f7;
  --color-link: #111111;
  --color-link-hover: #393939;
}

// Font families
:root {
  --font-hero: var(--font-league-gothic);
  --font-regular-bold: var(--font-helvetica-neue-bold);
  --font-family-regular: var(--font-helvetica-neue);
  --font-family: var(--font-helvetica-neue);
}

// Font sizes
:root {
  --font-size-header-1: 60px;
  --font-size-header-2: 46px;
  --font-size-header-3: 36px;
  --font-size-header-4: 28px;
  --font-size-header-1-content: 30px;
  --font-size-header-2-content: 22px;
  --font-size-header-3-content: 18px;
  --font-size-body: 14px;
  --font-size-body-s: 12px;
  --font-size-body-xs: 10px;
  --font-size-h1: 3.125rem;
  --font-size-h1-mobile: 1.875rem;
  --font-size-h2: 1.875rem;
  --font-size-h2-mobile: 1.5rem;
  --font-size-h3: 1.5rem;
  --font-size-h4: 1.25rem;
  --font-size-h5: 1.125rem;
  --font-size-small: 0.875rem;
  --font-size-x-small: 0.75rem;
  --font-size-super-small: 0.625rem;
}

// Font weights
:root {
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
}

// Line heights
:root {
  --line-height-body: 20px;
  --line-height-body-s: 18px;
  --line-height-body-xs: 16px;
  --line-height-header-1: 60px;
  --line-height-header-1-content: 37px;
  --line-height-header-2: 46px;
  --line-height-header-2-content: 28px;
  --line-height-header-3: 36px;
  --line-height-header-3-content: 24px;
  --line-height-header-4: 28px;
  --line-height-header-5: 24px;
  --line-height-l: 1.7;
  --line-height-m: 1.5;
  --line-height-s: 1.25;
  --line-height-xs: 1;
}

// Spacing
:root {
  --spacing-block: 2rem;
  --spacing-full-nav-height-with-lang: 80px;
  --spacing-large: 96px;
  --spacing-medium: 48px;
  --spacing-mega: 17.5rem;
  --spacing-menu-height: 64px;
  --spacing-mobile-top: 72px;
  --spacing-page-padding: 30px;
  --spacing-page-padding-mobile: 20px;
  --spacing-regular: 24px;
  --spacing-small: 16px;
  --spacing-small-medium: 32px;
  --spacing-xl: 192px;
  --spacing-xlarge: 6rem;
  --spacing-xsmall: 8px;
  --spacing-xxlarge: 9.5rem;
  --spacing-xxsmall: 4px;
}

html {
  font-size: 16px;
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  font-weight: var(--font-weight-regular);
  padding-top: var(--header-height);

  @media (min-width: 1025px) {
    padding-top: var(--header-height);
  }
}

p,
h1,
h2,
h3,
h4,
h5 {
  color: var(--color-text);
}

#root {
  min-height: 100vh;
}

img {
  max-width: 100%;
}

.no-js img.lazyload {
  display: none;
}

.map {
  position: relative;
  width: 100%;
}
